label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

/* Custom CSS */
.btn-primary{
  color:#f7f7f7;
  background-color:#04a0c0;
}

.btn-primary:hover{
  color:#f7f7f7;
  background-color:#03728e !important;
}

.bg-primary, .form-check-input:checked{
  color:#f7f7f7;
  background-color:rgba(4,160,200,255) !important;
}

.nav-link{
  color:#04a0c8;
}

.tr-pasive{
  background-color: #ececec;
  color: #b9b9b9;
 }

 footer {
  position: fixed;
  background-color: rgba(var(--bs-light-rgb),var(--bs-bg-opacity))!important;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px;
  text-align: center;
}

.t-generate{
  table-layout: fixed;
  width: 100%;
  max-height: 500px;  
}

.rules ul li{
  margin-top: 10px;
  margin-bottom: 10px;
}
.download{
  cursor: pointer;
  color:rgb(35, 103, 229);
}

.action-icon{
  cursor: pointer;
}

 .t-attendance{

 }

 .t-attendance th{
  
  
 }

 .t-attendance thead tr{
   
 }

 .t-attendance tbody td{
 
 }