/* .t-attendance  */

.freeze-table {
    border-spacing:0;
    font-size: 14px;
    padding: 6;
    border: 1px solid #ccc;
  }
  
/* thead th {
  top:0;
  position:sticky;
  background-color:#666;
  color:#000;
  z-index: 20;
  min-height: 3epx;
  height: 30px;
  text-align: left;
  } */

 
  tr:nth-child(even){
    background-color: #f2f2f2;
  }
  
  tbody th, td {
  padding: 6;
  outline: 1px solid #ccc;
  border: none;
  outline-offset: -1px;
  padding-left: 5px;
  }
  
  tbody tr {
  min-height: 25px;
  height: 25px;
  }

 .col-id-total {
    left:0px;
    position: sticky;
    vertical-align: middle;
    font-size: 1.1em;
    font-weight: bold;
    z-index: 200;
    }


  .col-email {
  left: 0;
  position: sticky;
  }
  
  .col-slackname {
  left: 150px;
  position: sticky;
  }

  .col-rate {
    left: 200px;
    position: sticky;
    }
  
  .fixed-header {
    z-index: 2000;
  }
  
  tr:nth-child(even) td[scope=row]{
    background-color: #f2f2f2;
  }
  
  tr:nth-child(odd) td[scope=row] {
  background-color: white;
 }
  